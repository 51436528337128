@import '../../../../scss/theme-bootstrap';

@if $cr20 == true {
  .header-search {
    .header-search__icon {
      position: relative;
      #{$ldirection}: unset;
      color: $color--white;
    }
    .header-search__form {
      position: fixed;
      top: 0;
      margin: 0;
      height: 100%;
      overflow-y: auto;
      z-index: $gnav-fixed-z-index + 1;
      &--content {
        padding: 0 $gnav-mobile-hpad--outer;
      }
      .header-search-icon {
        position: absolute;
        top: $gnav-mobile-hpad--outer;
        #{$ldirection}: $gnav-mobile-hpad--outer;
        height: 15px;
        width: 15px;
        fill: $color--gray--lightest;
      }
      .header-search__field {
        border: none;
        cursor: pointer;
        box-shadow: 0 1px 0 0 $color--gray--white;
        padding: 21px $gnav-mobile-hpad--outer;
        margin: 0;
        background-color: transparent;
        font-family: $helvetica-font;
        height: auto;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.25;
        letter-spacing: normal;
        &::placeholder {
          color: $color--gray--lightest;
        }
      }
      .header-search__close {
        position: fixed;
        top: 24px;
        width: 20px;
        height: 20px;
        #{$rdirection}: 20px;
        z-index: 2;
        display: flex;
        svg {
          width: 100%;
        }
      }
      .header-search__typeahead-wrapper {
        padding: 20px 0 0;
        @include breakpoint($bp--large-up) {
          padding: 0;
        }
      }
      .header-search__suggestions-wrapper {
        display: none;
        @include breakpoint($bp--large-up) {
          display: block;
        }
      }
    }
    &__typeahead-wrapper {
      .header-search__see-results {
        border: none;
        height: auto;
        padding-top: 15px;
        width: 100%;
        @include breakpoint($bp--large-up) {
          padding-top: 10px;
        }
        .header-search__term-result {
          @include body-text--small-bold;
          position: inherit;
          height: auto;
          padding-top: 0;
          display: flex;
          @include breakpoint($bp--large-up) {
            display: block;
            margin: 0 5px;
          }
        }
      }
      .search-suggestions-title {
        position: absolute;
        top: 50px;
        text-transform: uppercase;
        @include breakpoint($bp--large-up) {
          width: 17%;
          display: inline-block;
          position: static;
        }
      }
      .search-suggestions-title,
      .search-count,
      .search-results {
        @include sub-title--bold;
      }
      .header-search__suggestions {
        margin: 30px auto 0;
        @include breakpoint($bp--large-up) {
          margin: 10px auto 0;
        }
        .header-search__term-result {
          &.link {
            color: $color-text-grey;
            border: none;
            #{$ldirection}: 0;
            text-transform: initial;
            line-height: unset;
            height: auto;
            @include breakpoint($bp--large-up) {
              margin-#{$ldirection}: 6px;
            }
          }
        }
      }
      .header-search__result__price {
        @include sub-title;
        .product-price--discounted {
          color: $color--gray--lightest;
          text-decoration-line: line-through;
        }
      }
    }
    &-trending-title {
      @include sub-title--bold;
      width: 100%;
      display: flex;
      z-index: 501;
      padding: 20px 20px 0;
      text-transform: uppercase;
      @include breakpoint($bp--large-up) {
        display: block;
        padding: 0;
      }
    }
    .header-search__term-result {
      @include sub-title--bold;
      margin-top: 0;
      text-transform: uppercase;
    }
  }
  .header-search-overlay-bg {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
      background-color: $color--black;
      height: 100%;
      #{$ldirection}: 0;
      opacity: 0.8;
      position: fixed;
      top: 70px;
      width: 100%;
    }
  }
  .header-search-results {
    @include breakpoint($bp--large-up) {
      display: flex;
      margin-top: 20px;
    }
    .header-search__typeahead-wrapper {
      max-width: 1440px;
      display: flex;
      flex-wrap: wrap;
      .search-suggestions {
        @include breakpoint($bp--large-up) {
          display: flex;
          order: 1;
          margin-#{$ldirection}: 0;
        }
      }
      &.no-prods {
        display: none;
      }
      .search-suggestions ~ .header-search__results {
        @include breakpoint($bp--large-up) {
          align-self: end;
        }
      }
      .description-copy {
        padding-top: 10px;
      }
    }
    .site-header-formatter--hidden & {
      margin-top: 55px;
      transition: margin-top 1s;
    }
  }
  .header-search__results {
    border-top: none;
    padding: 25px 0;
    @include breakpoint($bp--largest-up) {
      padding: 0;
      margin: 0 auto 30px;
    }
  }
  .site-header-formatter__inner-content {
    .elc__popular-header,
    .esearch-nav__popular-list {
      width: 100%;
      margin: 0 auto;
      padding: 0 20px;
      position: relative;
      @include breakpoint($bp--largest-up) {
        float: #{$ldirection};
        width: 17%;
        max-width: 1440px;
      }
    }
    .elc__popular-header {
      @include sub-title--bold;
      margin-top: 0;
      text-transform: uppercase;
    }
    .search-suggestions {
      .esearch-nav__popular-list {
        padding: 0;
        @include breakpoint($bp--largest-up) {
          width: 100%;
          padding: 0 5px;
        }
      }
    }
    .esearch-nav__popular-list {
      margin-top: 18px;
      clear: both;
      @include breakpoint($bp--largest-up) {
        margin-top: 35px;
      }
      .esearch-nav__popular__item {
        @include body-text;
        box-shadow: none;
        height: auto;
        padding-bottom: 10px;
        @include breakpoint($bp--largest-up) {
          padding-bottom: 0;
          margin: 0 0 16px;
        }
        a,
        a:visited,
        a:hover {
          color: $color-text-grey;
          text-transform: none;
        }
        a {
          border-bottom: none;
        }
      }
    }
  }
  .search-overlay-landing {
    margin: 0 auto;
    z-index: 999;
    position: fixed;
    #{$ldirection}: 0;
    height: 90%;
    overflow-y: scroll;
    top: 62px;
    @include breakpoint($bp--largest-up) {
      top: unset;
      padding-top: 30px;
      height: auto;
    }
    .site-header-formatter--hidden & {
      margin-top: 35px;
      transition: margin-top 1s;
    }

    .sd-product-grid {
      float: #{$rdirection};
      width: 100%;
      @include breakpoint($bp--largest-up) {
        width: 83%;
        padding: 0;
      }
      .elc-product-brief-view {
        display: flex;
        flex-wrap: wrap;
      }
      .elc-product-grid-wrapper {
        margin: 0;
      }
      .elc-shade-swatches-wrapper,
      .elc-rating-component-container,
      .elc-footer-content-section,
      .elc-product-badge-wrapper {
        display: none;
      }
      .elc-product-brief {
        background: transparent;
        padding: 0 20px;
      }
      .elc-product-great-to-know-wrapper {
        font-size: 12px;
      }
      .elc-grid-item-product {
        width: 50%;
        @include breakpoint($bp--largest-up) {
          width: 25%;
        }
      }
      .elc-product-display-name-link {
        font-size: 12px;
      }
    }
  }
  @include breakpoint($bp--largest-up) {
    //968px
    .header-search {
      display: block;
      padding: 0 7px;
      background-color: $color--black;
      &.active {
        background-color: $color--white;
        color: $color--black;
      }
      &.header-search-display-input,
      &__suggestions-wrapper {
        display: block;
      }
    }
    .header-search-results,
    .search-overlay-landing,
    .elc__popular-header,
    .esearch-nav__popular-list {
      @include unset-reverse-text();
      width: 100%;
      position: fixed;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      z-index: 500;
      background: $color--white;
      color: $color--black;
      display: block;
      .header-search__suggestions {
        @include body-text;
        box-shadow: none;
        height: auto;
        padding-bottom: 10px;
        @include breakpoint($bp--large-up) {
          padding-bottom: 0;
        }
        .header-search__term-result {
          position: inherit;
          border-bottom: none;
          color: $color-text-grey;
        }
      }
      .header-search__results {
        border-top: none;
        order: 2;
        align-self: center;
      }
      .content {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
      }
    }
    .header-search.header-search-display-input {
      height: $site-banner-height;
      .search-active {
        background-color: $color--black;
        .icon--search {
          color: $color--white;
        }
      }
      .header-search__field {
        @include swap_direction(padding, 0 15px 0 0);
        font-family: $helvetica-font;
        color: $color--white;
        background-color: $color--black;
        border: none;
        border-bottom: 1px solid $color--border;
        box-shadow: none;
        height: 22px;
        width: 127px;
        float: $rdirection;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: -1px;
        font-weight: 700;
        cursor: inherit;
        margin: 0.15em 0;
        &:focus {
          border: none;
          border-bottom: 1px solid $color--border;
        }
        &::placeholder {
          text-transform: none;
          font-size: 12px;
          color: $color--gray--dusty;
        }
      }
      .header-search__form {
        @include transform(translateY(-50%));
        top: 50%;
        border: none;
        visibility: visible;
        opacity: 1;
        position: relative;
        background: transparent;
        height: auto;
        overflow-y: hidden;
        z-index: unset;
        &--content {
          overflow: hidden;
          max-width: 100%;
          padding: 0;
        }
      }
      .header-search__icon {
        top: 0;
        display: block;
        position: absolute;
        line-height: 22px;
        height: 22px;
        padding: 0;
        #{$rdirection}: 0;
        #{$ldirection}: auto;
        font-size: 13px;
        color: $color--black;
        z-index: 501;
        i {
          font-size: 15px;
          color: $color--white;
          height: 22px;
        }
      }
      .header-search__typeahead-wrapper,
      > .header-search__icon {
        display: none;
      }
      .header-search__typeahead-wrapper {
        max-width: 1440px;
      }
      .header-search__suggestions {
        &-wrapper {
          text-align: $ldirection;
          display: block;
        }
      }
    }
  }
  @include breakpoint($bp--xxlarge-up) {
    // 1280px
    .header-search {
      &.header-search-display-input {
        height: 50px;
      }
    }
  }
}
